<template>
  <q-markup-table flat>
    <thead>
      <tr>
        <th class="text-left">Type aansluiting(en)</th>
        <th class="text-center">EAN code(s)</th>
        <th class="text-right">Keuze</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="installation_elec in electricity"
        :key="installation_elec.connect_ean"
      >
        <td class="text-left">
          <q-icon name="bolt" style="font-size: 1.1rem" />Stroom
        </td>
        <td class="text-center">{{ installation_elec.connect_ean }}</td>
        <td class="text-right">
          <q-checkbox
            :disable="readonly"
            left-label
            v-model="installationElectricity"
            :true-value="installation_elec.connect_ean"
          />
        </td>
      </tr>
      <tr v-for="installation_gas in gas" :key="installation_gas.connect_ean">
        <td class="text-left">
          <q-icon name="whatshot" style="font-size: 1rem" /> Gas
        </td>
        <td class="text-center">{{ installation_gas.connect_ean }}</td>
        <td class="text-right">
          <q-checkbox
            :disable="readonly"
            left-label
            v-model="installationGas"
            :true-value="installation_gas.connect_ean"
          />
        </td>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { validator } from "../../plugins/validation.js";

//* VueUse
const $store = useStore();

//* Computed
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);
const gas = computed(() => $store.getters.installationdetails.gas);
const electricity = computed(
  () => $store.getters.installationdetails.electricity
);

const installationGas = computed({
  get() {
    return (
      ($store.getters.projectSpecific["EAN Gas"] != "" &&
        $store.getters.projectSpecific["EAN Gas"]) ??
      false
    );
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "EAN Gas",
      value: newVal,
      validated: validator("ean", newVal),
    });
  },
});
const installationElectricity = computed({
  get() {
    return (
      ($store.getters.projectSpecific["EAN Elektra"] != "" &&
        $store.getters.projectSpecific["EAN Elektra"]) ??
      false
    );
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "EAN Elektra",
      value: newVal,
      validated: validator("ean", newVal),
    });
  },
});
</script>
